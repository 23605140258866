import React, { useEffect, useState } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { useRef } from 'react';
import firebase from 'firebase/app';

import './Navbar.css';
import { useAuth } from '../../context/auth';

const NavBarMobile = ({ userRole }) => {
	const { authTokens, setAuthTokens } = useAuth();
	const navBarMobile = useRef(null);
	const [isAdmin, setIsAdmin] = useState(false);

	useEffect(() => {
		userRole === 'ADMINISTRATOR' ? setIsAdmin(true) : setIsAdmin(false);
	}, [userRole]);

	function logOut() {
		setAuthTokens();
		localStorage.clear();
		firebase.auth().signOut();
	}

	const items = [
		{
			label: 'Machine overview',
			icon: 'pi pi-fw pi-th-large',
			command: () => {
				window.location = '/machines';
			},
		},
		{
			label: 'Ingredients',
			icon: 'pi pi-fw pi-pencil',
			command: () => {
				window.location = '/ingredients';
			},
		},
		{
			label: 'Recipes',
			icon: 'pi pi-fw pi-list',
			command: () => {
				window.location = '/recipe';
			},
		},
		{
			label: 'Reference Intake',
			icon: 'pi pi-fw pi-chart-bar',
			command: () => {
				window.location = '/reference-intake';
			},
		},
		{
			label: 'Promotion codes',
			icon: 'pi pi-fw pi-ticket',
			command: () => {
				window.location = '/code';
			},
		},
		{
			label: 'Movies',
			icon: 'pi pi-fw pi-video',
			command: () => {
				window.location = '/movies';
			},
		},
		{
			label: 'Locations',
			icon: 'pi pi-fw pi-map-marker',
			command: () => {
				window.location = '/locations';
			},
			visible: isAdmin,
		},
		{
			label: 'Settings',
			icon: 'pi pi-fw pi-cog',
			command: () => {
				window.location = '/settings';
			},
			visible: isAdmin,
		},
		{ separator: true },
		{
			label: 'Logout',
			icon: 'pi pi-fw pi-power-off',
			command: () => {
				logOut();
			},
		},
	];

	return (
		<>
			<Menu model={items} popup ref={navBarMobile} className="menu-mobile" />
			<Button icon="pi pi-bars" onClick={(e) => navBarMobile.current.toggle(e)} />
		</>
	);
};

export default NavBarMobile;
