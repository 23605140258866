import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Button,
	Container,
	Dropdown,
	Grid,
	GridColumn,
	Header,
	Icon,
	Image,
	Input,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from 'semantic-ui-react';

import Alert from '../utils/Alert';
import ApiService from '../services/ApiService';
import LoadingContext from '../loading/LoadingContext';
import Spinner from '../loading/Spinner';
import { useAuth } from '../context/auth';

const api = new ApiService();

const Recipe = () => {
	const [options, setOptions] = useState([{ key: '1', text: 'All', value: '' }]);
	const filterOptions = [
		{ key: '1', text: 'Search', value: 'search' },
		{ key: '2', text: 'Categories', value: 'categories' },
		{ key: '3', text: 'None', value: 'none' },
	];
	const [recipes, setRecipes] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [filter, setFilter] = useState('');
	const [category, setCategory] = useState('');
	const [categoryFilterActive, setCategoryFilterActive] = useState(false);
	const [searchFilterActive, setSearchFilterActive] = useState(false);
	const history = useHistory();
	const { authTokens } = useAuth();
	const { showLoading, hideLoading } = useContext(LoadingContext);

	const editRecipe = async (recipe) => {
		history.push('/recipe/update', recipe);
	};

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		const getRecipes = async () => {
			showLoading();
			const response = await api.getRecipes(activePage - 1, headers);
			hideLoading();
			setTotalPages(response.data.totalPages);
			setRecipes(response.data.recipes);
		};
		getRecipes();
	}, []);

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		const fetchCategories = async () => {
			try {
				const response = await api.getRecipeCategories(headers);
				const categories = response.data.map((category, index) => ({
					key: index + 2, // Start from 2 since "All" is 1
					text: category.toLowerCase(),
					value: category.toUpperCase(),
				}));
				setOptions((prevOptions) => [...prevOptions, ...categories]);
			} catch (error) {
				console.error('Error fetching categories:', error);
			}
		};

		fetchCategories();
	}, []);

	const handlePaginationChange = async (e, { activePage }) => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		let response = {};
		if (filter != '') {
			response = await api.getRecipesByName(filter, activePage - 1, headers);
		} else if (category != '') {
			response = await api.getRecipesByCategory(category, activePage - 1, headers);
		} else {
			response = await api.getRecipes(activePage - 1, headers);
		}

		setActivePage(activePage);
		setRecipes(response.data.recipes);
	};

	const filterRecipe = async (e) => {
		setFilter(e.target.value);

		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		let response = {};
		if (e.target.value != '') {
			response = await api.getRecipesByName(e.target.value, activePage - 1, headers);
		} else {
			response = await api.getRecipes(activePage - 1, headers);
		}

		setActivePage(1);
		setTotalPages(response.data.totalPages);
		setRecipes(response.data.recipes);
	};

	const handleFilterOnCategories = async (e, { value }) => {
		setCategory(value);
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		let response = {};
		if (value.length > 0) {
			response = await api.getRecipesByCategory(value, activePage - 1, headers);
			setCategoryFilterActive(true);
		} else {
			response = await api.getRecipes(activePage - 1, headers);
			setCategoryFilterActive(false);
		}

		setActivePage(1);
		setTotalPages(response.data.totalPages);
		setRecipes(response.data.recipes);
	};

	const clearSearch = async () => {
		setFilter('');

		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		const response = await api.getRecipes(activePage - 1, headers);
		setTotalPages(response.data.totalPages);
		setRecipes(response.data.recipes);
	};

	const clearCategoryFilter = async () => {
		setCategory('');

		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		const response = await api.getRecipes(activePage - 1, headers);
		setTotalPages(response.data.totalPages);
		setRecipes(response.data.recipes);
	};

	const handleFilterSelect = async (e, { value }) => {
		if (value == 'search') {
			clearCategoryFilter();
			setSearchFilterActive(true);
			setCategoryFilterActive(false);
		} else if (value == 'categories') {
			clearSearch();
			setSearchFilterActive(false);
			setCategoryFilterActive(true);
		} else if (value == 'none') {
			clearCategoryFilter();
			clearSearch();
			setSearchFilterActive(false);
			setCategoryFilterActive(false);
		}
	};

	return (
		<Container>
			<Grid columns={2} padded>
				<Grid.Row>
					<Grid.Column>
						<Header as="h2">Recipes</Header>
					</Grid.Column>

					<Grid.Column textAlign="right" floated="right">
						<Dropdown
							text="Filter"
							icon="filter"
							labeled
							button
							className="icon"
							placeholder="Filter"
							options={filterOptions}
							onChange={handleFilterSelect}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>

			<Button
				onClick={() => history.push('/recipe/create')}
				content="Create"
				icon="plus"
				labelPosition="left"
				primary
			/>

			<Alert />
			<Spinner />

			<Grid columns={2} padded>
				<Grid.Row>
					{searchFilterActive && (
						<GridColumn className="search">
							<Input value={filter} icon="search" placeholder="Search..." onChange={filterRecipe} />

							{filter.length > 0 && <Button onClick={clearSearch} content="Clear" style={{ marginLeft: '10px' }} />}
						</GridColumn>
					)}

					{categoryFilterActive && (
						<GridColumn>
							<Dropdown
								placeholder="Categories"
								fluid
								selection
								options={options}
								onChange={handleFilterOnCategories}
							/>
						</GridColumn>
					)}
				</Grid.Row>
			</Grid>

			<Grid columns={1} padded>
				<Grid.Row>
					<GridColumn>
						<Table celled padded>
							<TableHeader>
								<TableRow>
									<TableHeaderCell>Recipes</TableHeaderCell>
									<TableHeaderCell>Image</TableHeaderCell>
									<TableHeaderCell>Label</TableHeaderCell>
									<TableHeaderCell>Description</TableHeaderCell>
									<TableHeaderCell>Categories</TableHeaderCell>
									<TableHeaderCell>Actions</TableHeaderCell>
								</TableRow>
							</TableHeader>
							<TableBody>
								{recipes
									.filter((recipe) => recipe.name !== null)
									.map((recipe, index) => (
										<TableRow key={recipe.name + index}>
											<TableCell>{recipe.name}</TableCell>
											<TableCell>
												<Image src={recipe.image} size="tiny"></Image>
											</TableCell>
											<TableCell>
												<Image src={recipe?.badgeIconUrl} size="tiny"></Image>
											</TableCell>
											<TableCell>{recipe.description}</TableCell>
											<TableCell>{recipe.categories}</TableCell>
											<TableCell>
												<Icon name="edit" onClick={() => editRecipe(recipe)} />
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>

						<Pagination activePage={activePage} onPageChange={handlePaginationChange} totalPages={totalPages} />
					</GridColumn>
				</Grid.Row>
			</Grid>
		</Container>
	);
};

export default Recipe;
