import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import './Navbar.css';
import firebase from 'firebase/app';
import { Button } from 'primereact/button';

const Navbar = ({ userRole }) => {
	const location = useLocation();
	const { authTokens, setAuthTokens } = useAuth();
	const [activeItem, setActiveItem] = useState();
	const [isAdmin, setIsAdmin] = useState(false);

	useEffect(() => {
		userRole === 'ADMINISTRATOR' ? setIsAdmin(true) : setIsAdmin(false);
	}, [userRole]);

	function logOut() {
		setAuthTokens();
		localStorage.clear();
		firebase.auth().signOut();
	}

	useEffect(() => {
		switch (location.pathname) {
			case '/machine':
				setActiveItem('machines');
				break;
			case '/machine/create':
				setActiveItem('machineCreate');
				break;
			case '/ingredient/create':
				setActiveItem('ingredientCreate');
				break;
			case '/recipe/create':
				setActiveItem('recipeCreate');
				break;
			case '/recipe':
				setActiveItem('recipes');
				break;
			case '/ingredients':
				setActiveItem('ingredients');
				break;
			case '/reference-intake':
				setActiveItem('reference-intake');
				break;
			case '/code':
				setActiveItem('codes');
				break;
			case '/movies':
				setActiveItem('movies');
				break;
			case '/locations':
				setActiveItem('locations');
				break;
			case '/settings':
				setActiveItem('settings');
				break;
			default:
				setActiveItem('machines');
				break;
		}
	}, [location]);

	return (
		<nav className="nav">
			<div className="nav-container">
				<div className="nav-header">
					<Link to="/">
						<img src="/logo_alberts.png" alt="logo" className="nav-header--logo" />
					</Link>
				</div>

				<ul className="nav-list">
					<li className={`nav-list-item ${activeItem === 'machines' ? 'nav-list-item--active' : ''}`}>
						<i className="pi pi-th-large nav-list-item--icon"></i>
						<Link to="/machines">Machines</Link>
					</li>

					<li className={`nav-list-item ${activeItem === 'recipes' ? 'nav-list-item--active' : ''}`}>
						<i className="pi pi-list nav-list-item--icon"></i>
						<Link to="/recipe">Recipes</Link>
					</li>

					<li className={`nav-list-item ${activeItem === 'ingredients' ? 'nav-list-item--active' : ''}`}>
						<i className="pi pi-pencil nav-list-item--icon"></i>
						<Link to="/ingredients">Ingredients</Link>
					</li>

					<li className={`nav-list-item ${activeItem === 'reference-intake' ? 'nav-list-item--active' : ''}`}>
						<i className="pi pi-chart-bar nav-list-item--icon"></i>
						<Link to="/reference-intake">Reference intake</Link>
					</li>
					<li className={`nav-list-item ${activeItem === 'codes' ? 'nav-list-item--active' : ''}`}>
						<i className="pi pi-ticket nav-list-item--icon"></i>
						<Link to="/code">Promotion codes</Link>
					</li>
					{isAdmin && (
						<li className={`nav-list-item ${activeItem === 'locations' ? 'nav-list-item--active' : ''}`}>
							<i className="pi pi-map-marker nav-list-item--icon"></i>
							<Link to="/locations">Locations</Link>
						</li>
					)}
					<li className={`nav-list-item ${activeItem === 'movies' ? 'nav-list-item--active' : ''}`}>
						<i className="pi pi-video nav-list-item--icon"></i>
						<Link to="/movies">Movies</Link>
					</li>

					{isAdmin && (
						<li className={`nav-list-item ${activeItem === 'settings' ? 'nav-list-item--active' : ''}`}>
							<i className="pi pi-cog nav-list-item--icon"></i>
							<Link to="/settings">Settings</Link>
						</li>
					)}
				</ul>
			</div>

			<div className="nav-user">
				<div className="nav-user--container">
					<div className="nav-user--inner-container">
						<div className="nav-user--avatar">{authTokens && authTokens.claims.name.charAt(0).toUpperCase()}</div>
						<div>
							<div className="nav-user--name">{authTokens && authTokens.claims.name}</div>

							<div className="nav-user--role">{userRole && userRole.toLowerCase()}</div>
						</div>
					</div>
					<Button icon="pi pi-sign-out" rounded text severity="secondary" aria-label="logout" onClick={logOut} />
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
