import React, { useEffect, useState } from 'react';
import {Grid, GridColumn, Container, Button, Table, TableHeader, TableHeaderCell, TableBody, TableRow, TableCell, Icon, Dropdown} from 'semantic-ui-react';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player'
import './MachineMovies.css';
const api = new ApiService();

const MachineMovies = (props) => {
    const [machineMovies, setMachineMovies] = useState(props.location.state.movieMetadataList);
    const [allMovies, setAllMovies] = useState([]);
    const { authTokens } = useAuth();
    const history = useHistory();
    const headers =  {
        "X-Firebase-Auth-Client":  authTokens.token
    }

    const movieTypeOptions =[
        { key: "1", value: "PROMOTION", text: "Promotion" },
        { key: "2", value: "ADVERTISEMENT", text: "Advertisement" }
    ]

    const removeMovie = (movie, index) => {

        const tempMovies = machineMovies.slice();
        tempMovies.splice(index,1)
        setMachineMovies(tempMovies);
        allMovies.push(movie);
        setAllMovies(allMovies);
    }

    const handleSubmit = () => {
        const machineId = props.location.state.machineId;
        const data = machineMovies.map(movie => ({id: movie.id, type: movie.movieType}));
        api.updateMachineMovies(machineId, data, headers);

    }

    const addMovie = (movie, index) => {
        allMovies.splice(index,1);
        const tempMovies  = machineMovies.slice();
        tempMovies.push(movie);
        setMachineMovies(tempMovies);
        setAllMovies(allMovies);

    }

    const editMovie = async (movie) => {
        history.push("/movie/update", movie);
    }

    const fetchAllMovies = async () => {
        const allMoviesResponse = await api.getAllMovies(headers);
        //filter ingredients that are already in the machine
        const result = allMoviesResponse.data.filter(data =>
           !machineMovies.some(item => item.id === data.id));
        setAllMovies(result);
    }

    const changeMovieType = (e, {value, id})  => {
        if(machineMovies[id] !== undefined){
            const temp = machineMovies.slice();
            temp[id].movieType = value;
            setMachineMovies(temp);
        }
    }

    useEffect(() => {
        fetchAllMovies();
    },[setAllMovies]);

    return(
        <Container>
            <Grid columns={2} padded>
                <GridColumn>
            <Table celled padded>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Movie</TableHeaderCell>
                        <TableHeaderCell>Actions</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {machineMovies.map((movie,index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <span>{movie.originalName}</span>
                                    <ReactPlayer controls width='180px' height='320px' url={movie.url}></ReactPlayer>
                                </TableCell>
                                {/* <TableCell><Image src={ingredient.flavor.icon} size="mini"></Image></TableCell> */}
                                <TableCell>
                                <Dropdown
                                        id={index}
                                        clearable
                                        selection
                                        placeholder='Select the movie type'
                                        options={movieTypeOptions}
                                        value={movie.movieType}
                                        onChange={changeMovieType}
                                        />
                                </TableCell>
                                <TableCell>
                                    <Icon name='trash alternate outline' onClick={() => removeMovie(movie, index)}/>
                                    {/* <Icon name='edit' onClick={() => editMovie(movie, index)}/> */}
                                </TableCell>
                            </TableRow>
                    ))}
                </TableBody>
            </Table>
            </GridColumn>
            <GridColumn>
            <Table celled padded>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Movie</TableHeaderCell>
                        <TableHeaderCell>Actions</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allMovies.map((movie,index) => (
                            <TableRow key={movie.id}>
                                <TableCell>
                                <span>{movie.originalName}</span>
                                <ReactPlayer width='180px' height='320px' url={movie.url}></ReactPlayer>
                                </TableCell>
                                <TableCell>
                                        <Icon name='plus' onClick={() => addMovie(movie, index)}/>
                                        <Icon name='edit' onClick={() => editMovie(movie)}/>
                                </TableCell>
                            </TableRow>

                    ))}
                </TableBody>
            </Table>
            </GridColumn>
            <Button color='green' basic onClick={handleSubmit}>Update</Button>
            </Grid>
        </Container>
    )
}

export default MachineMovies;
