import React, { useEffect, useState, useContext } from 'react';
import { Button, Header, Container, Table, TableBody, TableCell, TableRow, TableHeader, Icon, TableHeaderCell } from 'semantic-ui-react';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import { useHistory } from 'react-router-dom';
import Spinner from '../loading/Spinner';
import LoadingContext from '../loading/LoadingContext'
import Alert from '../utils/Alert';

const api = new ApiService();

const ReferenceIntake = () => {

    const [referenceIntakes, setReferenceIntakes] = useState([]);
    const history = useHistory();
    const { authTokens } = useAuth();
    const { showLoading, hideLoading } = useContext(LoadingContext)

    const editReferenceIntake = async (referenceIntake) => {
        history.push("/reference-intake/update", referenceIntake);
    }

    useEffect(() => {
        const headers = {
            "X-Firebase-Auth-Client": authTokens.token
        }
        const getReferenceIntake = async () => {
            showLoading()
            const response = await api.listReferenceIntakes(headers);
            hideLoading()
            setReferenceIntakes(response.data);
        };
        getReferenceIntake();
    }, [])

    const createReferenceIntake = async () => {
        history.push("/reference-intake/create");
    }


    return (
        <Container className='container_table' fluid>
            <Header as='h2'>Reference Intake</Header>
            <Alert />
            <Spinner />
            <Table celled padded>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Country</TableHeaderCell>
                        <TableHeaderCell>Carbohydrate</TableHeaderCell>
                        <TableHeaderCell>Energy</TableHeaderCell>
                        <TableHeaderCell>Fat</TableHeaderCell>
                        <TableHeaderCell>Fiber</TableHeaderCell>
                        <TableHeaderCell>Folate</TableHeaderCell>
                        <TableHeaderCell>Protein</TableHeaderCell>
                        <TableHeaderCell>Salt</TableHeaderCell>
                        <TableHeaderCell>Saturated Fat</TableHeaderCell>
                        <TableHeaderCell>Sodium</TableHeaderCell>
                        <TableHeaderCell>Sugars</TableHeaderCell>
                        <TableHeaderCell>Vitamine A</TableHeaderCell>
                        <TableHeaderCell>Vitamine B12</TableHeaderCell>
                        <TableHeaderCell>Vitamine B6</TableHeaderCell>
                        <TableHeaderCell>Vitamine C</TableHeaderCell>
                        <TableHeaderCell>Vitamine D</TableHeaderCell>
                        <TableHeaderCell>Vitamine E</TableHeaderCell>
                        <TableHeaderCell>Vitamine K</TableHeaderCell>
                        <TableHeaderCell>Actions</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {referenceIntakes.map((referenceIntake, index) => (
                        <TableRow key={index}>
                            <TableCell>{referenceIntake.country}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.carbohydrate}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.energy}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.fat}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.fiber}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.folate}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.protein}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.salt}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.saturatedFat}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.sodium}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.sugars}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.vitamineA}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.vitamineB12}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.vitamineB6}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.vitamineC}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.vitamineD}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.vitamineE}</TableCell>
                            <TableCell>{referenceIntake.nutritionalValues.vitamineK}</TableCell>
                            <TableCell>
                                <Icon name='edit' onClick={() => editReferenceIntake(referenceIntake)} />
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button onClick={createReferenceIntake}> <Icon name="plus" ></Icon>Create reference intake</Button>
        </Container>
    )

}

export default ReferenceIntake;
