import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

import RequiredMessage from './RequiredMessage';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';

const CodeDialog = ({
	codeDialog,
	submittedCode,
	setSubmittedCode,
	setCodeDialog,
	code,
	setCode,
	codes,
	setCodes,
	promotions,
	promotion,
	setPromotion,
	promotionOptions,
	dateFormat,
	toast,
	codeDialogTitle,
}) => {
	const api = new ApiService();
	const { authTokens } = useAuth();

	const onInputChange = (e, name) => {
		const val = (e.target && e.target.value) || '';

		let _code = { ...code };
		_code[`${name}`] = val;
		setCode(_code);
	};

	const onInputNumberChange = (e, name) => {
		const val = e.value || 0;
		let _code = { ...code };

		_code[`${name}`] = val;

		setCode(_code);
	};

	const onDateChange = (e, name) => {
		const val = e.value || '';
		let _code = { ...code };

		_code[`${name}`] = val;

		console.log('onDateChange', _code);

		setCode(_code);
	};

	const onChangeDropDown = (e, name) => {
		const val = e.value || '';
		let _code = { ...code };

		_code[`${name}`] = val;

		setCode(_code);

		const temp = promotions.filter((promotion) => promotion.id === val);

		setPromotion({
			id: temp[0].id,
			type: temp[0].type,
			value: temp[0].value ? temp[0].value : 0,
			unit: temp[0].unit ? temp[0].unit : '',
		});
	};

	const hideDialog = () => {
		setSubmittedCode(false);
		setCodeDialog(false);
	};

	const findIndexById = (id) => {
		let index = -1;

		for (let i = 0; i < codes.length; i++) {
			if (codes[i].id === id) {
				index = i;
				break;
			}
		}

		return index;
	};

	const errorValidation = (code) => {
		// if code.name is less than 4 characters or more than 16 characters or contains special characters and is not capitalised then return and show error
		if (!/^[A-Z0-9]{4,16}$/.test(code.name)) {
			toast.current.show({
				severity: 'error',
				summary: 'Validation Error',
				detail: 'Name must be between 4 and 16 characters and only contain capital letters and numbers',
				life: 3000,
			});
			return true;
		}

		// if startTime is after endTime then return and show error
		else if (code.startTime > code.endTime) {
			toast.current.show({
				severity: 'error',
				summary: 'Validation Error',
				detail: 'Start date cannot be after end date',
				life: 3000,
			});
			return true;
		}

		return false;
	};

	const saveCode = () => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		setSubmittedCode(true);

		if (!code) return;

		// Check if validation failed
		if (errorValidation(code)) {
			return; // Stop execution if there was a validation error
		}

		if (
			code.name.trim() &&
			code.maxClaims &&
			code.maxClaimsPerUser &&
			code.startTime &&
			code.endTime &&
			code.promotionId
		) {
			let _codes = [...codes];
			let startTimeFormatted = new Date(code.startTime).toISOString();
			let endTimeFormatted = new Date(code.endTime).toISOString();

			let codeObjForApi = {
				name: code.name,
				description: code.description,
				maxClaims: code.maxClaims,
				maxClaimsByUser: code.maxClaimsPerUser,
				startTime: startTimeFormatted,
				endTime: endTimeFormatted,
				promotionId: promotion.id,
			};

			// if code has an id, it means it is an existing code so we update it
			if (code.id) {
				try {
					api.updateCode(code.id, codeObjForApi, headers);

					let codeObjForTable = {
						id: code.id,
						name: code.name,
						description: code.description,
						maxClaims: code.maxClaims,
						maxClaimsByUser: code.maxClaimsPerUser,
						startTime: new Date(code.startTime).toLocaleDateString('be-NL', dateFormat),
						endTime: new Date(code.endTime).toLocaleDateString('be-NL', dateFormat),
						totalClaimed: 0,
						promotion: {
							id: promotion.id,
							type: promotion.type,
							value: promotion.value,
							unit: promotion.unit,
						},
					};

					// update table
					let index = findIndexById(code.id);
					_codes[index] = codeObjForTable;
					setCodes(_codes);

					setCodeDialog(false);

					toast.current.show({
						severity: 'success',
						summary: 'Successful',
						detail: 'Code Updated',
						life: 3000,
					});
				} catch (error) {
					console.error(error);

					toast.current.show({
						severity: 'error',
						summary: 'Error',
						detail: 'Code Update Failed',
						life: 3000,
					});
				}
			} else {
				try {
					api.createCode(codeObjForApi, headers);
					console.log('codeObjForApi', codeObjForApi);

					let codeObjForList = {
						name: code.name,
						description: code.description,
						maxClaims: code.maxClaims,
						maxClaimsByUser: code.maxClaimsPerUser,
						startTime: new Date(code.startTime).toLocaleDateString('be-NL', dateFormat),
						endTime: new Date(code.endTime).toLocaleDateString('be-NL', dateFormat),
						totalClaimed: 0,
						promotion: {
							id: promotion.id,
							type: promotion.type,
							value: promotion.value,
							unit: promotion.unit,
						},
					};
					_codes.push(codeObjForList);
					setCodes(_codes);

					setCodeDialog(false);

					toast.current.show({
						severity: 'success',
						summary: 'Successful',
						detail: 'Code Created',
						life: 3000,
					});
				} catch (error) {
					console.error(error);

					toast.current.show({
						severity: 'error',
						summary: 'Error',
						detail: 'Code Creation Failed',
						life: 3000,
					});
				}
			}
		} else {
			toast.current.show({
				severity: 'error',
				summary: 'Validation Error',
				detail: 'Fields cannot be empty',
				life: 3000,
			});
		}
	};

	const codeDialogFooter = (
		<>
			<Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
			<Button label="Save" icon="pi pi-check" onClick={saveCode} />
		</>
	);

	return (
		<>
			<Dialog
				visible={codeDialog}
				style={{ width: '32rem' }}
				breakpoints={{ '960px': '75vw', '641px': '90vw' }}
				header={codeDialogTitle}
				modal
				className="p-fluid"
				footer={codeDialogFooter}
				onHide={hideDialog}
			>
				<div className="field">
					<label htmlFor="name" className="font-bold">
						Name
					</label>
					<InputText
						id="name"
						value={code.name}
						onChange={(e) => onInputChange(e, 'name')}
						required
						autoFocus
						className={classNames({ 'p-invalid': submittedCode && !code.name })}
					/>
					{!submittedCode && <small className="p-info">(4-16 characters, only capital letter and number)</small>}
					{submittedCode && !code.name && <RequiredMessage message="Name is required." />}
				</div>

				<div className="field">
					<label htmlFor="description" className="font-bold">
						Description
					</label>
					<InputTextarea
						autoResize
						id="description"
						value={code.description}
						onChange={(e) => onInputChange(e, 'description')}
						rows={3}
						cols={30}
					/>
				</div>

				<hr className="my-5 border-indigo-100 opacity-30" />

				<div className="formgrid grid">
					<div className=" field col">
						<label htmlFor="maximumClaims" className="font-bold block">
							Maximum claims
						</label>
						<InputNumber
							id="maximumClaims"
							value={code.maxClaims}
							onValueChange={(e) => onInputNumberChange(e, 'maxClaims')}
							showButtons
							buttonLayout="horizontal"
							decrementButtonClassName="p-button-secondary"
							incrementButtonClassName="p-button-secondary"
							incrementButtonIcon="pi pi-plus"
							decrementButtonIcon="pi pi-minus"
							step={1}
							required
						/>
						{submittedCode && !code.maxClaims && <RequiredMessage message="Maximum claims is required." />}
					</div>

					<div className=" field col">
						<label htmlFor="maximumClaimsPerUser" className="font-bold block">
							Maximum claims per user
						</label>
						<InputNumber
							id="maximumClaimsPerUser"
							value={code.maxClaimsPerUser}
							onValueChange={(e) => onInputNumberChange(e, 'maxClaimsPerUser')}
							showButtons
							buttonLayout="horizontal"
							decrementButtonClassName="p-button-secondary"
							incrementButtonClassName="p-button-secondary"
							incrementButtonIcon="pi pi-plus"
							decrementButtonIcon="pi pi-minus"
							step={1}
							required
						/>
						{submittedCode && !code.maxClaimsPerUser && (
							<RequiredMessage message="Maximum claims per user is required." />
						)}
					</div>
				</div>

				<hr className="my-5 border-indigo-100 opacity-30" />

				<div className="formgrid grid">
					<div className=" field col">
						<label htmlFor="startTime" className="font-bold block">
							Start date
						</label>
						<Calendar
							id="startTime"
							value={code.startTime}
							onChange={(e) => onDateChange(e, 'startTime')}
							showIcon
							showButtonBar
							showTime
							dateFormat="dd/mm/yy"
							required
						/>
						{submittedCode && !code.startTime && <RequiredMessage message="Start date is required." />}
					</div>

					<div className=" field col">
						<label htmlFor="endTime" className="font-bold block">
							End date
						</label>
						<Calendar
							id="endTime"
							value={code.endTime}
							onChange={(e) => onDateChange(e, 'endTime')}
							showIcon
							showButtonBar
							showTime
							dateFormat="dd/mm/yy"
							required
						/>
						{submittedCode && !code.endTime && <RequiredMessage message="End date is required." />}
					</div>
				</div>

				<hr className="my-5 border-indigo-100 opacity-30" />

				<div className="formgrid grid">
					<div className=" field col">
						<label htmlFor="promotion" className="font-bold block">
							Promotion
						</label>
						<Dropdown
							id="promotion"
							value={code.promotionId}
							options={promotionOptions}
							onChange={(e) => onChangeDropDown(e, 'promotionId')}
							placeholder="Select a promotion"
							optionLabel="text"
							required
						/>
					</div>

					<div className=" field col">
						<label htmlFor="value" className="font-bold block">
							Value
						</label>
						<InputText id="value" value={promotion.value} disabled />
					</div>

					<div className=" field col">
						<label htmlFor="unit" className="font-bold block">
							Unit
						</label>
						<InputText id="unit" value={promotion.unit} disabled />
					</div>
				</div>
				{submittedCode && !code.promotionId && <RequiredMessage message="Promotion is required." />}
			</Dialog>
		</>
	);
};

export default CodeDialog;
