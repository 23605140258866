import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Header } from 'semantic-ui-react';
import { FilterMatchMode } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { Dialog } from 'primereact/dialog';

import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
import './Locations.css';
const api = new ApiService();

export default function Locations() {
	let emptyLocation = {
		street: '',
		num: '',
		postalCode: '',
		city: '',
		country: '',
	};
	const [location, setLocation] = useState(emptyLocation);
	const [locations, setLocations] = useState([]);
	const [linkedLocations, setLinkedLocations] = useState([]);
	const [expandedRows, setExpandedRows] = useState(null);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [loading, setLoading] = useState(true);
	const { authTokens } = useAuth();
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const [submitted, setSubmitted] = useState(false);
	const [locationDialog, setLocationDialog] = useState(false);
	const [inputError, setInputError] = useState(false);
	const [inputFieldError, setInputFieldError] = useState({
		street: false,
		num: false,
		postalCode: false,
		city: false,
		country: false,
	});

	const toast = useRef(null);
	const msgs = useRef(null);

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const fetchLocations = async () => {
			try {
				const response = await api.getAllLocations(headers);
				setLocations(response.data);
			} catch (error) {
				console.log(error);
			}
		};

		fetchLocations();

		return () => {
			setLocations([]);
		};
	}, [authTokens.token]);

	useEffect(() => {
		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};

		const fetchMachinesToLinkToLocation = async () => {
			try {
				const response = await api.getMachines(headers);

				response.data.forEach((machine) => {
					if (!machine.location) return;

					// find the location of the machine
					const location = locations.find((location) => location.locationId === machine.location.locationId);
					if (location) {
						// add the machine to the location
						if (!location.machines) {
							location.machines = [];
						}

						location.machines.push(machine);
					}
				});

				setLinkedLocations(locations);
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		};

		fetchMachinesToLinkToLocation();

		return () => {
			setLoading(true);
		};
	}, [authTokens.token, locations]);

	const allowExpansion = (rowData) => {
		return rowData.machines?.length > 0;
	};

	const expandAll = () => {
		let _expandedRows = {};

		linkedLocations.forEach((p) => {
			if (p.machines?.length > 0) {
				_expandedRows[`${p.locationId}`] = true;
			}
		});

		setExpandedRows(_expandedRows);
	};

	const collapseAll = () => {
		setExpandedRows(null);
	};

	const rowExpansionTemplate = (data) => {
		return (
			<div className="subdata">
				<h5 className="text-indigo-500">
					{data.machines.length > 1 ? `Machines (${data.machines.length})` : 'Machine'}
				</h5>
				<ul className="subdata-list">
					{data.machines.map((machine, index) => {
						return (
							<li key={index}>
								{machine.customerName ? (
									<div>
										<span className="font-bold">{machine.customerName} </span>
										<span>({machine.name})</span>
									</div>
								) : (
									<div>
										<span className="font-bold">{machine.name}</span>
									</div>
								)}
							</li>
						);
					})}
				</ul>
			</div>
		);
	};

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const textEditor = (options) => {
		// input error is set to true when the input is empty and the user tries to submit the form add class p-invalid to the empty input
		return (
			<InputText
				type="text"
				value={options.value}
				onChange={(e) => options.editorCallback(e.target.value)}
				className={inputError && inputFieldError[options.field] ? 'p-invalid' : ''}
			/>
		);
	};

	const onRowEditComplete = (e) => {
		if (msgs.current) {
			msgs.current.clear();
		}

		const oldData = e.data;
		const newData = e.newData;

		const location = {
			city: newData.city,
			country: newData.country,
			address: newData.street,
			number: newData.num,
			postalCode: newData.postalCode,
		};

		// if the user didn't change anything, don't update the location
		if (
			oldData.city === newData.city &&
			oldData.country === newData.country &&
			oldData.street === newData.street &&
			oldData.num === newData.num &&
			oldData.postalCode === newData.postalCode
		) {
			toast.current.show({
				severity: 'info',
				summary: 'No Changes',
				detail: 'No changes were made',
				life: 3000,
			});

			return;
		}

		const headers = {
			'X-Firebase-Auth-Client': authTokens.token,
		};
		const updateLocation = async () => {
			try {
				await api.updateLocation(oldData.locationId, location, headers);
			} catch (error) {
				console.error(error);

				toast.current.show({
					severity: 'error',
					summary: 'Error',
					detail: 'Location Update Failed',
					life: 3000,
				});
			}
		};

		updateLocation();

		const updatedLocations = [...linkedLocations];
		const index = updatedLocations.findIndex((location) => location.locationId === oldData.locationId);
		updatedLocations[index] = newData;
		setLinkedLocations(updatedLocations);

		toast.current.show({
			severity: 'success',
			summary: 'Success',
			detail: 'Location Updated',
			life: 3000,
		});

		setInputError(false);
		setInputFieldError({
			street: false,
			num: false,
			postalCode: false,
			city: false,
			country: false,
		});
	};

	const onRowEditInit = (e) => {
		if (msgs.current) {
			msgs.current.clear();
			msgs.current.show({
				severity: 'warn',
				summary: 'Editing Location',
				detail:
					'You are now editing a location. This has major consequences on multiple machines linked to this location. Use with caution! ',
				sticky: true,
				closeable: false,
				life: 1000000,
			});
		}
	};

	const onRowEditCancel = (e) => {
		if (msgs.current) {
			msgs.current.clear();
		}

		toast.current.show({
			severity: 'info',
			summary: 'Cancelled',
			detail: 'Edit Cancelled',
			life: 3000,
		});

		setInputError(false);
		setInputFieldError({
			street: false,
			num: false,
			postalCode: false,
			city: false,
			country: false,
		});
	};

	const rowEditValidator = (location) => {
		const showToast = () => {
			toast.current.show({
				severity: 'error',
				summary: 'Validation Error',
				detail: 'Fields cannot be empty',
				life: 3000,
			});
		};

		switch (true) {
			case !location.street:
				setInputFieldError({ ...inputFieldError, street: true });
				showToast();
				setInputError(true);
				break;
			case !location.num:
				setInputFieldError({ ...inputFieldError, num: true });
				showToast();
				setInputError(true);
				break;
			case !location.postalCode:
				setInputFieldError({ ...inputFieldError, postalCode: true });
				showToast();
				setInputError(true);
				break;
			case !location.city:
				setInputFieldError({ ...inputFieldError, city: true });
				showToast();
				setInputError(true);
				break;
			case !location.country:
				setInputFieldError({ ...inputFieldError, country: true });
				showToast();
				setInputError(true);
				break;
			default:
				setInputError(false);
				break;
		}

		if (!location.street || !location.num || !location.postalCode || !location.city || !location.country) {
			return false;
		}

		return true;
	};

	const openNew = () => {
		setLocation(emptyLocation);
		setSubmitted(false);
		setLocationDialog(true);
	};

	const hideDialog = () => {
		setSubmitted(false);
		setLocationDialog(false);
	};

	const saveLocation = () => {
		setSubmitted(true);

		console.log('location', location);
		if (!location) return;
		console.log('location2', location);

		if (
			location.street.trim() &&
			location.num.trim() &&
			location.postalCode.trim() &&
			location.city.trim() &&
			location.country.trim()
		) {
			try {
				let _locations = [...locations];
				let _location = { ...location };
				const headers = {
					'X-Firebase-Auth-Client': authTokens.token,
				};

				const locationObjectForApi = {
					address: _location.street,
					number: _location.num,
					postalCode: _location.postalCode,
					city: _location.city,
					country: _location.country,
				};

				api.addLocation(locationObjectForApi, headers);
				// add the locationId to the location object otherwise react will complain about a missing key
				_location.locationId = locations.length + 1;
				_locations.push(_location);
				setLocations(_locations);

				setLocationDialog(false);

				toast.current.show({
					severity: 'success',
					summary: 'Successful',
					detail: 'Location Created',
					life: 3000,
				});
			} catch (error) {
				console.error(error);

				toast.current.show({
					severity: 'error',
					summary: 'Error',
					detail: 'Location Creation Failed',
					life: 3000,
				});
			}
		} else {
			toast.current.show({
				severity: 'error',
				summary: 'Validation Error',
				detail: 'Fields cannot be empty',
				life: 3000,
			});
		}
	};

	const onInputChange = (e, name) => {
		const val = (e.target && e.target.value) || '';

		console.log('location', location);

		let _location = { ...location };
		_location[`${name}`] = val;
		setLocation(_location);
	};

	const header = (
		<div className="flex justify-content-between">
			<div className="flex justify-content-end">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
				</span>
			</div>

			<div className="flex flex-wrap justify-content-end gap-2">
				{!expandedRows ? (
					<Button icon="pi pi-plus" label="Expand All" onClick={expandAll} className="p-button-text" />
				) : (
					<Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} className="p-button-text" />
				)}

				<Button className="ml-3" label="New" icon="pi pi-plus" onClick={openNew} />
			</div>
		</div>
	);

	const locationDialogFooter = (
		<React.Fragment>
			<Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
			<Button label="Save" icon="pi pi-check" onClick={saveLocation} />
		</React.Fragment>
	);

	return (
		<>
			<Header as="h2">Locations</Header>

			<Toast ref={toast} />
			<Messages ref={msgs} />
			<div className="mt-5 main-datatable">
				<DataTable
					value={linkedLocations}
					expandedRows={expandedRows}
					onRowToggle={(e) => {
						setExpandedRows(e.data);
					}}
					loading={loading}
					rowExpansionTemplate={rowExpansionTemplate}
					dataKey="locationId"
					header={header}
					tableStyle={{ minWidth: '60rem' }}
					paginator
					rows={10}
					globalFilterFields={['street', 'num', 'postalCode', 'city', 'country']}
					emptyMessage="No locations found."
					filters={filters}
					alwaysShowPaginator={false}
					removableSort
					onRowEditComplete={onRowEditComplete}
					editMode="row"
					onRowEditInit={onRowEditInit}
					onRowEditCancel={onRowEditCancel}
					rowEditValidator={rowEditValidator}
				>
					<Column expander={allowExpansion} style={{ width: '5rem' }} />
					<Column style={{ width: '3rem' }} />
					<Column field="street" header="Street" sortable editor={(options) => textEditor(options)} />
					<Column field="num" header="Number" editor={(options) => textEditor(options)} />
					<Column field="postalCode" header="Zip Code" sortable editor={(options) => textEditor(options)} />
					<Column field="city" header="City" sortable editor={(options) => textEditor(options)} />
					<Column field="country" header="Country" sortable editor={(options) => textEditor(options)} />
					<Column
						rowEditor
						headerStyle={{ width: '10%', minWidth: '6rem' }}
						bodyStyle={{ textAlign: 'center' }}
					></Column>
				</DataTable>
			</div>

			<Dialog
				visible={locationDialog}
				style={{ width: '32rem' }}
				breakpoints={{ '960px': '75vw', '641px': '90vw' }}
				header="New Location"
				modal
				className="p-fluid"
				footer={locationDialogFooter}
				onHide={hideDialog}
			>
				<div className="field">
					<label htmlFor="street" className="font-bold">
						Street
					</label>
					<InputText
						id="street"
						value={location.street}
						onChange={(e) => onInputChange(e, 'street')}
						required
						autoFocus
						className={classNames({ 'p-invalid': submitted && !location.street })}
					/>
					{submitted && !location.street && <small className="p-error">Street is required.</small>}
				</div>

				<div className="formgrid grid">
					<div className="field col">
						<label htmlFor="street number" className="font-bold">
							Number
						</label>
						<InputText
							id="street number"
							value={location.num}
							onChange={(e) => onInputChange(e, 'num')}
							required
							autoFocus
							className={classNames({ 'p-invalid': submitted && !location.num })}
						/>
						{submitted && !location.num && <small className="p-error">Number is required.</small>}
					</div>
					<div className="field col">
						<label htmlFor="postal code" className="font-bold">
							Postal Code
						</label>
						<InputText
							id="postal code"
							value={location.postalCode}
							onChange={(e) => onInputChange(e, 'postalCode')}
							required
							autoFocus
							className={classNames({ 'p-invalid': submitted && !location.postalCode })}
						/>
						{submitted && !location.postalCode && <small className="p-error">Postal Code is required.</small>}
					</div>
				</div>

				<div className="field col">
					<label htmlFor="city" className="font-bold">
						City
					</label>
					<InputText
						id="city"
						value={location.city}
						onChange={(e) => onInputChange(e, 'city')}
						required
						autoFocus
						className={classNames({ 'p-invalid': submitted && !location.city })}
					/>
					{submitted && !location.city && <small className="p-error">City is required.</small>}
				</div>

				<div className="field col">
					<label htmlFor="country" className="font-bold">
						Country
					</label>
					<InputText
						id="country"
						value={location.country}
						onChange={(e) => onInputChange(e, 'country')}
						required
						autoFocus
						className={classNames({ 'p-invalid': submitted && !location.country })}
					/>
					{submitted && !location.country && <small className="p-error">Country is required.</small>}
				</div>
			</Dialog>
		</>
	);
}
