import React, { useContext } from 'react'
import { Message } from 'semantic-ui-react'
import ErrorContext from './ErrorContext'

const Alert = () => {
  const { errorMessage } = useContext(ErrorContext)
  return (
        <>
            { (errorMessage !== '') && Array.isArray(errorMessage) &&
                errorMessage.map(message => (
                    <Message key={message} negative>
                        {message}
                    </Message>
                ))

            }
            { (errorMessage !== '') && !Array.isArray(errorMessage) &&
                    <Message negative>
                        {errorMessage}
                    </Message>
            }
        </>
  )
}

export default Alert
