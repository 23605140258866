import React, { useState } from 'react'
import LoadingContext from './LoadingContext'
import PropTypes from 'prop-types'

let finished = false

const LoadingProvider = ({ children }) => {
  const showLoading = () => {
    finished = false
    setTimeout(() => showLoadingCondition(), 500)
  }

  const showLoadingCondition = () => {
    if (!finished) {
      toggleLoading(prevState => {
        return {
          ...prevState,
          showSpinner: true
        }
      })
    } else {
      toggleLoading(prevState => {
        finished = true
        return {
          ...prevState,
          showSpinner: false
        }
      })
    }
  }

  const hideLoading = () => {
    finished = true
    toggleLoading(prevState => {
      return {
        ...prevState,
        showSpinner: false
      }
    })
  }

  const loadingState = {
    showSpinner: false,
    showLoading,
    hideLoading
  }

  const [loading, toggleLoading] = useState(loadingState)

  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  )
}

LoadingProvider.propTypes = {
  children: PropTypes.any
}

export default LoadingProvider
