import React, {useState} from 'react';
import {  Form,  Button, Grid, Input, Message } from 'semantic-ui-react';
import ApiService from '../services/ApiService';
import { useAuth } from '../context/auth';
const api = new ApiService();

const ReferenceIntakeCreator =  (props) => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState(props.location.state ? props.location.state.country: "");
    const [energy, setEnergy] = useState(props.location.state ? props.location.state.nutritionalValues.energy: "");
    const [protein, setProtein] = useState(props.location.state ? props.location.state.nutritionalValues.protein: "");
    const [fat, setFat] = useState(props.location.state ? props.location.state.nutritionalValues.fat: "");
    const [saturatedFat, setSaturatedFat] = useState(props.location.state ? props.location.state.nutritionalValues.saturatedFat: "");
    const [carbs, setCarbs] = useState(props.location.state ? props.location.state.nutritionalValues.carbohydrate: "");
    const [fiber, setFiber] = useState(props.location.state ? props.location.state.nutritionalValues.fiber: "");
    const [sugar, setSugar] = useState(props.location.state ? props.location.state.nutritionalValues.sugars: "");
    const [salt, setSalt] = useState(props.location.state ? props.location.state.nutritionalValues.salt: "");
    const [sodium, setSodium] = useState(props.location.state ? props.location.state.nutritionalValues.sodium: "");
    const [magnesium, setMagnesium] = useState(props.location.state ? props.location.state.nutritionalValues.magnesium: "");
    const [potassium, setPotassium] = useState(props.location.state ? props.location.state.nutritionalValues.potassium: "");
    const [folate, setFolate] = useState(props.location.state ? props.location.state.nutritionalValues.folate: "");
    const [vitamineA, setVitamineA] = useState(props.location.state ? props.location.state.nutritionalValues.vitamineA: "");
    const [vitamineC, setVitamineC] = useState(props.location.state ? props.location.state.nutritionalValues.vitamineC: "");
    const [vitamineD, setVitamineD] = useState(props.location.state ? props.location.state.nutritionalValues.vitamineD: "");
    const [vitamineE, setVitamineE] = useState(props.location.state ? props.location.state.nutritionalValues.vitamineE: "");
    const [vitamineK, setVitamineK] = useState(props.location.state ? props.location.state.nutritionalValues.vitamineK: "");
    const [vitamineB6, setVitamineB6] = useState(props.location.state ? props.location.state.nutritionalValues.vitamineB6: "");
    const [vitamineB12, setVitamineB12] = useState(props.location.state ? props.location.state.nutritionalValues.vitamineB12: "");
    const { authTokens } = useAuth();
    const headers =  {
        "X-Firebase-Auth-Client":  authTokens.token
    }

    const handleSubmit = async () => {
        setLoading(true);
        const nutritionalValues = {
            energy,
            protein,
            fat: fat,
            saturatedFat: saturatedFat,
            carbohydrate: carbs,
            fiber,
            sugars: sugar,
            salt: salt,
            sodium: sodium,
            magnesium,
            potassium,
            folate,
            vitamineA,
            vitamineC,
            vitamineD,
            vitamineE,
            vitamineK,
            vitamineB6,
            vitamineB12,
        }

        try{
            if(props.location.state){
                await api.updateReferenceIntake(props.location.state.id, {country, nutritionalValues}, headers)
            }else{
                await api.createReferenceIntake({country, nutritionalValues}, headers);
            }
            setSuccess(true);
        }catch(error){
            setError(true);
        }
        setLoading(false);
    }

    return(
        <Grid padded>
            <Form loading={loading} onSubmit={handleSubmit} success={success} error={error}>
                <Form.Field>
                    <label>Country</label>
                    <Input placeholder='country' value={country} onChange={e => setCountry(e.target.value)}/>
                </Form.Field>
                <Form.Group widths='equal'>
                <Form.Field>
                    <label>Energy (kcal)</label>
                    <Input placeholder='energy (kcal)' value={energy} onChange={e => setEnergy(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Protein (g)</label>
                    <Input placeholder='protein (g)' value={protein} onChange={e => setProtein(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Fat (g)</label>
                    <Input placeholder='Fat (g)' value={fat} onChange={e => setFat(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Saturate fat (g)</label>
                    <Input placeholder='Saturated fat (g)' value={saturatedFat} onChange={e => setSaturatedFat(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Carbohydrate (g)</label>
                    <Input placeholder='carbohydrate (g)' value={carbs} onChange={e => setCarbs(e.target.value)} />
                </Form.Field>
                </Form.Group>
                <Form.Group>
                <Form.Field>
                    <label>Fiber (g)</label>
                    <Input placeholder='fiber (g)' value={fiber} onChange={e => setFiber(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Salt (mg)</label>
                    <Input placeholder='salt (mg)' value={salt} onChange={e => setSalt(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Sodium (mg)</label>
                    <Input placeholder='sodium (mg)' value={sodium} onChange={e => setSodium(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Sugars (g)</label>
                    <Input placeholder='sugars (g)' value={sugar} onChange={e => setSugar(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Magnesium (mg)</label>
                    <Input placeholder='magnesium (mg)' value={magnesium} onChange={e => setMagnesium(e.target.value)} />
                </Form.Field>
                </Form.Group>
                <Form.Group>
                <Form.Field>
                    <label>Potassium (mg)</label>
                    <Input placeholder='potassium (mg)' value={potassium} onChange={e => setPotassium(e.target.value)}/>
                </Form.Field>
                <Form.Field>
                    <label>Folate (µg)</label>
                    <Input placeholder='folate (µg)' value={folate} onChange={e => setFolate(e.target.value)}/>
                </Form.Field>
                <Form.Field>
                    <label>Vitamine A (µg)</label>
                    <Input placeholder='vitamine A (µg)' value={vitamineA} onChange={e => setVitamineA(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Vitamine C (mg)</label>
                    <Input placeholder='vitamine C (mg)' value={vitamineC} onChange={e => setVitamineC(e.target.value)}/>
                </Form.Field>
                <Form.Field>
                    <label>Vitamine D (µg)</label>
                    <Input placeholder='vitamine D (µg)' value={vitamineD} onChange={e => setVitamineD(e.target.value)}/>
                </Form.Field>
                </Form.Group>
                <Form.Group>
                <Form.Field>
                    <label>Vitamine E (mg)</label>
                    <Input placeholder='vitamine E (mg)' value={vitamineE} onChange={e => setVitamineE(e.target.value)}/>
                </Form.Field>
                <Form.Field>
                    <label>Vitamine K (µg)</label>
                    <Input placeholder='vitamine K (µg)' value={vitamineK} onChange={e => setVitamineK(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Vitamine B6 (mg)</label>
                    <Input placeholder='vitamine B6 (mg)' value={vitamineB6} onChange={e => setVitamineB6(e.target.value)}/>
                </Form.Field>
                <Form.Field>
                    <label>Vitamine B12 (µg)</label>
                    <Input placeholder='vitamine B12 (µg)' value={vitamineB12} onChange={e => setVitamineB12(e.target.value)}/>
                </Form.Field>
                </Form.Group>
                <Message
                    success
                    header='Completed'
                    content="New reference intake added"
                    />
                <Message
                    error
                    header='Failed'
                    content="Failed to add new reference intake"
                    />
                <Button color='green' basic type='submit'>Submit</Button>
            </Form>
        </Grid>
    )
}

export default ReferenceIntakeCreator;
