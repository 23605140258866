import React, {useState, useEffect, useContext} from 'react';
import { Grid, Form, Message, Input, Button, Header, Container, Table, TableHeaderCell, Icon } from 'semantic-ui-react';
import ApiService from '../services/ApiService';
import Spinner from '../loading/Spinner';
import LoadingContext from '../loading/LoadingContext'
import Alert from '../utils/Alert';
import { useAuth } from '../context/auth';

const api = new ApiService();

const Settings = () => {

    const [success, setSuccess] = useState (false);
    const [error, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [operators, setOperators] = useState([])
    const { authTokens } = useAuth();
    const { showLoading, hideLoading } = useContext(LoadingContext)
    const headers =  {
        "X-Firebase-Auth-Client":  authTokens.token
    }

    const getOperators = async () => {
        showLoading()
        const response = await api.getOperators(headers);
        hideLoading()
        setOperators(response.data);
    };

    useEffect(()=>{
        getOperators();
    }, [])

    const handleSubmit = async () => {
        showLoading()
        try{
            await api.addOperator({email:email},headers);
            getOperators()
            hideLoading()
            setSuccess(true); 
        }catch(error){
            hideLoading()
            setError(true); 
        }
    }

    const removeOperator = async (email) => {
        await api.removeOperator({email: email}, headers)
        getOperators()
    }

    return(
        <Container>
            <Header as='h2'>Operators</Header>
            <Alert/>
            <Spinner/>
            <Grid columns={2} padded>
                <Grid.Column>
                    <Table celled padded>
                        <Table.Header>
                            <Table.Row>
                                <TableHeaderCell>Email</TableHeaderCell>
                                <TableHeaderCell>Role</TableHeaderCell>
                                <TableHeaderCell>Action</TableHeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {operators.map((operator,index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell>{operator.login}</Table.Cell>
                                        <Table.Cell>{operator.role}</Table.Cell>
                                        <Table.Cell><Icon name='trash alternate outline' onClick={() => removeOperator(operator.login)}/></Table.Cell>
                                    </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid>
            <Grid padded>
                <Grid.Row>
                    <Grid.Column>
                        <Header>Add an operator</Header>
                        <Form onSubmit={handleSubmit} success={success} error={error}>
                            <Form.Group >
                            <Form.Field>
                                <label>Email</label>
                                <Input placeholder='email' value={email}  onChange={e => setEmail(e.target.value)}/>
                            </Form.Field>
                            </Form.Group>
                            <Message
                                success
                                header='Completed'
                                content="Operator added"
                                />
                            <Message
                                error
                                header='Failed'
                                content="Failed to add operator"
                                />
                            <Button color='green' basic type='submit'>Submit</Button>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )

}

export default Settings;