import React from 'react'
import { Image, Card, Header, Button} from 'semantic-ui-react';
import ApiService from '../services/ApiService';
import {Redirect} from 'react-router-dom';
import { useAuth } from "../context/auth";
import './Login.css';
import firebase from 'firebase/app';
const api = new ApiService();


const Login = (props) => {
  const { authTokens, setAuthTokens } = useAuth();
  const referer = props.location.state ? props.location.state.referer || '/' : '/';

  async function responseGoogle(user){
    const token = await user.getIdTokenResult();
    try{
      // DISABLED THIS LINE BECAUSE IT WAS CAUSING A BUG BUT IT SHOULD BE ENABLED
      // await api.login(token.token);
      setAuthTokens(token);
    }catch(error){
      console.log('error', error);
      //setAuthTokens("");
      alert("You are not allowed to log in, please contact Alberts to gain access");
    }
  }

  const googleLogin = async () => {
    try {
      const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithPopup(googleAuthProvider);
      const user = firebase.auth().currentUser;
      responseGoogle(user)
    } catch (error) {
      alert("You are not allowed to log in");
    }
  }


  if (authTokens) {
    return <Redirect to={referer} />;
  }

  return(
    <div className="login">
      <Card className= 'login-card'>
        <Image className="login-image" size='small' centered src='/icon_216.png' />
        <Card.Content>
          <Header className='login-header' textAlign="center">Login</Header>
          <Button className= 'login-button' onClick={googleLogin}>LOGIN</Button>
        </Card.Content>
      </Card>
    </div>
)}

export default Login
